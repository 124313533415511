import React, { useContext, useState } from 'react';
import { Box, Stack, Typography, Collapse, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyContext } from '../../../router/router';
import TitleColumn from '../../Title/Column';

const newsItems = [
    { 
        id: '01', 
        date: '2024.11.02', 
        category: 'お知らせ', 
        title: '限定割引のお知らせ', 
        content: '現在、海外クラウドファンディング代行サービスに限定割引キャンペーンを実施中です。２回目以降のお申込みのお客様には、初期費用の10%オフが適用されます。日本の伝統工芸やユニークな製品を海外市場で紹介したい方に最適な機会です。詳しくは当社のサポートチームにお問い合わせください。'
    },
    { 
        id: '02', 
        date: '2024.11.01', 
        category: 'お知らせ', 
        title: '料金改定のお知らせ', 
        content: '海外クラウドファンディング代行サービスのサイトが新しくアップデートされ、新たな料金プランが追加されました。基本プランでは初期費用が抑えられ、プロジェクトサポートを必要とする事業者様には特別なパッケージプランもご用意しています。また、成功報酬型のプランも新たに導入され、プロジェクトが成立した場合にのみ手数料が発生する仕組みです。詳細は当社ウェブサイトでご確認ください。'
    },
    { 
        id: '03', 
        date: '2024.10.01', 
        category: 'お知らせ', 
        title: 'サイト公開', 
        content: '海外クラウドファンディング代行サービスの公式サイトが公開されました。私たちは、日本の魅力ある製品やサービスを海外に紹介するためのクラウドファンディング支援を提供しています。多言語対応と、現地向けのマーケティングサポートも充実しています。今後のプロジェクトにぜひご期待ください。'
    }
];

const NewsSection: React.FC = () => {
    const { subTitleFontSize, mainFontSize, isXS, isSmall } = useContext(MyContext);
    const _isMobile = isXS || isSmall;
    const [expanded, setExpanded] = useState<string | false>(false);
    const [showAll, setShowAll] = useState(false);

    const handleExpandClick = (id: string) => {
        setExpanded(prev => (prev === id ? false : id));
    };

    // 表示するニュース項目を2件に制限
    const displayedNews = showAll ? newsItems : newsItems.slice(0, 2);

    return (
        <Stack
            id="news"
            position="relative"
            direction="column"
            bgcolor="#F5FBFE"
            width="100%"
            alignItems="center"
            pt={_isMobile ? 3 : 2}
            pb={_isMobile ? 3 : 2}
            px={2}
        >
            {/* タイトルカラム */}
            <TitleColumn title="最新情報" title_en="NEWS" fontSize={subTitleFontSize + 4} />

            <Stack
                position="relative"
                width="90%"
                maxWidth="500px"
                mt={2}
                borderRadius={2}
                p={1}
                alignItems="center"
            >
                <Stack width="100%" spacing={1.5} alignItems={"center"} >
                    {displayedNews.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                width:"100%",
                                backgroundColor: '#FFFFFF',
                                borderRadius: '6px',
                                boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
                                padding: '8px 12px',
                                transition: 'transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
                                },
                            }}
                        >
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Box onClick={() => handleExpandClick(item.id)} sx={{ cursor: 'pointer', flex: 1 }}>
                                    {/* 横並びのCategoryとTitle */}
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontWeight: 'bold',
                                                color: '#fff',
                                                backgroundColor: '#383e86',
                                                borderRadius: '10px',
                                                padding: '2px 6px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {item.category}
                                        </Typography>

                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: mainFontSize - 1 }}>
                                            {item.title}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem', mt: 0.5 }}>
                                        {item.date}
                                    </Typography>
                                </Box>
                                <IconButton
                                    onClick={() => handleExpandClick(item.id)}
                                    aria-expanded={expanded === item.id}
                                    aria-label="show more"
                                    sx={{
                                        transform: expanded === item.id ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.2s ease',
                                        padding: '4px', // アイコンボタンのパディングを減らして小さく
                                    }}
                                >
                                    <ExpandMoreIcon fontSize="small" />
                                </IconButton>
                            </Stack>
                            <Collapse in={expanded === item.id} timeout="auto" unmountOnExit>
                                {/* Content Card Style */}
                                <Box
                                    sx={{
                                        mt: 1,
                                        p: 1.5,
                                        backgroundColor: '#f9f9f9',
                                        borderRadius: '6px',
                                        border: '1.5px solid #ddd',
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontSize: mainFontSize - 2, color: '#333' }}>
                                        {item.content}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </Box>
                    ))}

                    {/* もっと見る・閉じるボタン */}
                    {newsItems.length > 2 && (
                        <Stack pt={2}>
                            <Button
                                variant="contained"
                                onClick={() => setShowAll(!showAll)}
                                sx={{
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold',
                                    backgroundColor: '#383E86',
                                    color: '#fff',
                                    borderRadius: '3px',
                                    padding: '8px 8px',
                                    width: "200px",
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#0056b3',
                                    },
                                }}
                            >
                                {showAll ? '閉じる' : 'もっと見る'}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NewsSection;