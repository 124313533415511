import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import titleBannerImage from  '../../../img/titleBanner.png'
import ApplyButton from '../../Button/applyButton';
import { LetterByLetter } from 'reidea-common';

const TitleBanner: React.FC = () => {
    const { titleFontSize, subTitleFontSize, headerSize, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    // 行間の設定(比率)
    const titleLineHeight = 1.2
    const subTitleLineHeight = 1.4

    // 文字間隔の設定(px)
    const titleLetterSpacing = isXS ? 10 : 6
    const subTitleLetterSpacing = 5

    // 画像サイズ(px)
    const imgWidth = isXS ? 260 :
                     isSmall ? 360 :
                     isMedium ? 520 : 
                     isLarge ? 650 : 0
    
    // モバイル判定
    const _isMobile = isSmall || isXS

    // 汎用フォントスタイル
    const fontStyle = { color:"white", whiteSpace:"pre-wrap", width:"100%", transition: `font-size ${0.15}s ease, -webkit-text-stroke ${0.15}s ease`,}
    // メインタイトルにのみ用いるフォントスタイル
    const titleFontStyle = {...fontStyle, '-webkit-text-stroke': `${isLarge ? 2.0 : 1.0}px currentColor`,
    }

    return (
        // PC版のデザイン
        !isXS ? 
            <Stack direction={"row"} width={"100%"} minWidth={"100%"} justifyContent={"center"} height={imgWidth+100+"px"} bgcolor={"#383E86"} >
                <Stack width={100+"%"} position={"relative"} direction={"row"} justifyContent={"center"}  top={headerSize}>
                    <Stack position={"relative"} left={_isMobile ? 20 : 40} top={_isMobile ? 20 : headerSize-20} spacing={4}>
                        <Typography sx={{...titleFontStyle}} fontWeight={600} fontSize={titleFontSize} lineHeight={titleLineHeight} letterSpacing={titleLetterSpacing} >
                            {"海外クラファン\n"}
                            <span style={{ fontSize: titleFontSize*2 }}>0</span>円から一括代行
                        </Typography>
                        <Stack width={_isMobile ? "100%" : "80%"} spacing={4}>
                            <Stack spacing={1}>
                                <LetterByLetter text={'ページ制作から運営、海外配送まで'}
                                    isAnimated={true} delay={70} offsetY={15} style={{ color:"white", fontSize:subTitleFontSize-6, fontWeight:"bold"}} />
                                <LetterByLetter text={'一括でサポート・代行いたします'}
                                    isAnimated={true} delay={70} offsetY={15} style={{ color:"white", fontSize:subTitleFontSize-6, fontWeight:"bold"}} />
                            </Stack>
                            <ApplyButton fontSize={subTitleFontSize-8+"px"} text='無料相談はこちら　 ▶︎'/>
                        </Stack>
                    </Stack>
                    <Stack position={"relative"} left={_isMobile ? 20 : 10} top={-0} width={imgWidth+"px"} sx={{transition:"width 0.15s ease"}}>
                        <Stack component="img" alt='kaigai crowdfunding title banner' src={titleBannerImage} width={"100%"} sx={{ aspectRatio:"1/1", objectFit:"cover", }}/>
                    </Stack>
                </Stack>
            </Stack>
        :
        // スマホ版のデザイン
            <Stack pt={headerSize+"px"} pb={6} spacing={2} direction={"column"} width={"100%"} minWidth={"100%"} justifyContent={"flex-start"} alignItems={"center"} bgcolor={"#383E86"} >
                <Stack component="img" alt='kaigai crowdfunding title banner' src={titleBannerImage} width={"53%"} sx={{ aspectRatio:"1/1", objectFit:"cover", }}/>
                <Typography textAlign={'center'} sx={{...titleFontStyle}} fontWeight={700} fontSize={titleFontSize*1.2} lineHeight={titleLineHeight} letterSpacing={titleLetterSpacing} >
                    {"海外クラファン\n"}
                    <span style={{ fontSize: titleFontSize*2.4 }}>0</span>円から一括代行
                </Typography>
                <Stack>
                    <LetterByLetter isAnimated={true} delay={70} offsetY={15} 
                        text={"ページ制作から運営、海外配送まで"} style={{  color:"white", textAlign:"center", fontWeight:"bold", fontSize:subTitleFontSize-2, lineHeight:subTitleLineHeight+0.3, letterSpacing:subTitleLetterSpacing}}  />
                    <LetterByLetter isAnimated={true} delay={70} offsetY={15} 
                        text={"一括でサポート・代行いたします"} style={{  color:"white", textAlign:"center", fontWeight:"bold", fontSize:subTitleFontSize-2, lineHeight:subTitleLineHeight+0.3, letterSpacing:subTitleLetterSpacing}}  />
                </Stack>
                <Stack width={"60%"} alignItems={"center"}>
                    <ApplyButton fontSize={subTitleFontSize-3+"px"} text='無料相談はこちら　 ▶︎'/>
                </Stack>
            </Stack>
    );
};

export default TitleBanner;