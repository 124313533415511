import React from 'react';
import { Button, keyframes } from '@mui/material';
import { motion } from 'framer-motion';

interface Props {
  text: string;
  fontSize?: string;
  color?: string;
  isTop?: boolean;
}

// @keyframesを使用してアニメーションを定義
const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;



const ApplyButton = ({text, fontSize, color='linear-gradient(to right, #43cea2, #185a9d)', isTop=false}: Props) => {
  
  
  const handleScroll = () => {
    const element = document.getElementById("contact");
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };


  return (
    <motion.div
        whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
    <Button
      onClick={() => {handleScroll()}}
      sx={{
        width: 'auto',
        background: color,
        color: 'white',
        fontSize:fontSize,
        borderRadius: '50px',
        padding: {
          xs: '8px 24px',
          sm: '9px 26px',
          md: '10px 28px'
        },
        border:isTop ? 'none':'1px solid white',
        textTransform: 'none',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          background: 'linear-gradient(to right, #3cb9a0, #164e8d)',
          boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
          backgroundSize: '200% 200%', // グラデーションのサイズを2倍にしてアニメーションをスムーズにする
          animation: `${gradientAnimation} 7s ease infinite`,
          animationDirection: 'alternate', // アニメーションの再生方向を交互にする
        },
        backgroundSize: '200% 200%', // グラデーションのサイズを2倍にしてアニメーションをスムーズにする
        animation: `${gradientAnimation} 7s ease infinite`,
        animationDirection: 'alternate', // アニメーションの再生方向を交互にする
      }}
    >
      {text}
      
    </Button>
    </motion.div>
  )
}

export default ApplyButton
