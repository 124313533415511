import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid} from "reidea-common"

// テキストの設定
const title : string = "海外クラウドファンディングとは"
const subTitle : string = "あらかじめ売れる数を把握してからの海外進出"


const ContactBanner: React.FC = () => {

    const { titleFontSize, subTitleFontSize,mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const subTitleLineHeight = 1.4
    const subTitleLetterSpacing = 3

    const _isMobile = isXS || isSmall;


    return (
        <Stack direction={"column"} width={"100%"} justifyContent={"center"} minWidth={"100%"} alignItems={"center"} spacing={4} bgcolor={"#fced71"} >

            <Stack width={"90%"} py={_isMobile ? 4 : 7} zIndex={2} spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Typography sx={{ color:"#383E86", whiteSpace:"pre-wrap", textAlign:"center"}} fontWeight={600} fontSize={mainFontSize+6} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                    {"まずは無料のご相談から、"+(isXS ? "\n" : "")+"お気軽にお問い合わせください。"}
                </Typography>
                <Stack width={_isMobile ? "80%" : "50%"} alignItems={"center"} >
                    <ApplyButton fontSize={subTitleFontSize-3+"px"} text='無料相談はこちら　 ▶︎'/>
                </Stack>
            </Stack>
            
        </Stack>
    );
};

export default ContactBanner;