import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../router/router';
import titleBannerImage from  '../../img/titleBanner.png'


interface Props {
    title:string;
    subTitle: string;
    description: string;
    image:string;

    titleFontSize: number;
    contentFontSize: number;

    height?: number;
    img_ratio?: string;
    spacing?: number;
    display_img?: boolean;
    alt: string;
}

// コンテストのミニカルーセルを実装
const ServiceCard: React.FC<Props> = (props) => {
    const borderRadius = 10
    return (
        <Stack width={"100%"} spacing={props.spacing ?? 3} height={props.height} boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.2)'} border={"2px solid #383E86"} borderRadius={borderRadius+"px"} bgcolor={"white"} alignItems={"center"}>
            <Stack py={1} alignItems={"center"} width={"100%"} bgcolor={"#383E86"}border={"2px solid #383E86"} sx={{borderTopLeftRadius:borderRadius-6, borderTopRightRadius: borderRadius-6}}>
                <Typography color={"white"} fontWeight={600} fontSize={props.titleFontSize-2} letterSpacing={1.2} >
                    {props.title}
                </Typography>
            </Stack>
            {props.display_img && <Box component={"img"} alt={props.alt} src={props.image} width={props.img_ratio ?? "80%"} sx={{aspectRatio:"1.2/1"}} />}
            <Stack width={"100%"} px={2} spacing={props.spacing ?? 3}>
                <Typography display={"flex"} alignItems={"center"} justifyContent={"center"} height={(props.contentFontSize+4) * 2} color={"#383E86"} textAlign={"center"} fontWeight={600} fontSize={props.contentFontSize+4} letterSpacing={1.2} 
                sx={{
                    whiteSpace:"pre-wrap"
                }} >
                    {props.subTitle}
                </Typography>
                <Typography color={"#202020"} fontWeight={400} fontSize={props.contentFontSize} letterSpacing={1.2} >
                    {props.description}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ServiceCard;