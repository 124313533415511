import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import merit1 from  '../../../img/merit1.png'
import merit2 from  '../../../img/merit2.png'
import merit3 from  '../../../img/merit3.png'
import merit4 from  '../../../img/merit4.png'
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, FlexCarousel} from "reidea-common"
import MeritCarouselCard from '../../Card/meritCarouselCard';
import CarouselButton from '../../Button/carouselButton';


const Merit: React.FC = () => {

    const {  subTitleFontSize, mainFontSize, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const meritCards = [
        {
          number: '01',
          img:merit1,
          alt: "Large market",
          title: '圧倒的な市場規模',
          content: isSmall ? '海外のクラファンサイトは、特大の市場規模を誇っており、その規模は日本国内の約100倍にも上ります。そのため最終的な調達額も大きくなりやすいです。'
                            : '海外のクラファンサイトは、特大の市場規模を誇っており、その規模は日本国内の約100倍にも上ります。そのため最終的な調達額も大きくなりやすいです。'
        },
        {
          number: '02',
          img:merit2,
          alt: "Low lisk",
          title: 'リスクが低い受注生産型',
          content: isSmall ? '売れる数が分かってから、製造・納品することができるため、在庫を抱える心配がなく、非常に低リスクで海外進出へ挑戦できます。'
                            : '売れる数が分かってから、製造・納品することができるため、在庫を抱える心配がなく、非常に低リスクで海外進出へ挑戦できます。'
        },
        {
          number: '03',
          img:merit3,
          alt: "Understanding demand",
          title: '海外需要の把握・顧客の獲得',
          content: isSmall ? '海外クラファンを行うことで、実際の需要が正確に分かります。また単なる資金調達に留まらず、海外における初期顧客との関係性を築くことができます。'
                            : '海外クラファンを行うことで、実際の需要が正確に分かります。また単なる資金調達に留まらず、海外における初期顧客との関係性を築くことができます。'
        },
        {
          number: '04',
          img:merit4,
          alt: "Low commission",
          title: '国内よりも低い販売手数料',
          content: isSmall ? '海外クラファンは、国内のクラファンよりも販売手数料が低く、国内と比べても約半分ほどの10%の手数料で掲載することができます。'
                            :'海外クラファンは、国内のクラファンよりも販売手数料が低く、国内と比べても約半分ほどの10%の手数料で掲載することができます。'
        }
      ];

    // カルセールで表示する要素
    const renderMeritCard = (card: any) => (
        <MeritCarouselCard
            number={card.number}
            title={card.title}
            content={card.content}
            fontSize={mainFontSize+1}
            width={isMedium || isLarge ? 530 : 260}
            displayImg={isXS}
            img={card.img}
            alt={card.alt}
        />
    );
    const slides = meritCards.map(renderMeritCard);
    const _isMobile = isXS || isSmall;

    const [ selectedID, setSelectedID] = useState<string | null>("01")

    return (
        <Stack id={"merit"} position={"relative"} direction={"column"} bgcolor={"#F5FBFE"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 12}>
            {/* タイトルカラム */}
            <TitleColumn title={"海外クラファンのメリット"} title_en='MERIT' fontSize={subTitleFontSize+5}/>
            
            <Stack position={"relative"} width={"90%"} mt={2} left={0}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    {/* PC版の時は左側に画像を表示 */}
                    {!isXS && <Stack component="img" src={meritCards.filter((meritCard:any) => meritCard.number === selectedID)[0].img} width={"30%"} maxWidth={450} sx={{ aspectRatio:"1.2/1", objectFit:"cover", }}/>}
                    
                    <Stack position={"relative"} width={isXS ? "100%" : "65%"}>
                        {/* カルセールを表示 */}
                        <FlexCarousel slide_min_width={isMedium || isLarge ? 550 : 280} items={slides} loop otherTransparent 
                            setSelectedID={setSelectedID} IDs={meritCards.map((merit:any) => merit.number)} 
                            prevButton={
                                <Stack position={"absolute"} left={isXS ? -15 : isMedium ? -40 : 10} top={"50%"}>
                                    <CarouselButton direction='prev'/>
                                </Stack>}
                            nextButton={
                                <Stack position={"absolute"} right={isXS ? -15 : 10} top={"50%"}>
                                    <CarouselButton direction='next'/>
                                </Stack>}
                            playOnInit
                            displayButton
                            selectedDotButtonStyle={{backgroundImage: "-webkit-linear-gradient(left, #43CEA2 0%, #185A9D 100%)"}}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Merit;