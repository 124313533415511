import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid} from "reidea-common"
import YoutubeCard from '../../Card/youtubeCard';

// テキストの設定
const title : string = "海外クラファン\n0円から一括代行"
const subTitle : string = "RE-IDEAはあなたの技術を\n世界に届ける会社です"
const description : string = "RE-IDEAはあなたの技術を\n世界に届けるお手伝いをする会社です"


const Works: React.FC = () => {

    const { titleFontSize, subTitleFontSize, mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const _isMobile = isXS || isSmall;


    return (
        <Stack id={"works"} direction={"column"} width={"100%"} bgcolor={"#F5FBFE"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <TitleColumn title='導入事例' title_en='WORKS' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={contentRatio*100+"%"}>
                <BaseGrid cols={1} spacingY={4} items={
                    [
                        <YoutubeCard fontSize={mainFontSize} videoid='7D_9d5oyqSM' title='日本発の伝統工芸品' description='日本の伝統工芸品を扱う企業が初めての海外クラウドファンディングに挑戦し、文化的なニュアンスの違いや市場特有の壁に直面していました。弊社は、掲載文の精密な校正と翻訳に加え、動画撮影・編集も自社で行い、海外の支援者により魅力的な訴求を行いました。'/>,
                        <YoutubeCard fontSize={mainFontSize} videoid='th8RXDnlSgU' title='日本発のマッサージ器具' description='日本の整体院が初めての海外クラウドファンディングに挑戦し、慣れない英語圏の市場に挑戦しました。弊社は掲載文章や動画だけでなく、画像撮影・編集も自社で行い、より魅力的なプロジェクトになるよう心がけました。'/>
                    ]
                }/>
                
            </Stack>
        </Stack>
    );
};

export default Works;