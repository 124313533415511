import React, { useState } from 'react'
import {Stack, Typography, ButtonBase, Button} from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


// フォントファミリーの定義
const fontFamilies = {
  jost: "'Jost', 'Helvetica', 'Arial', sans-serif",
  poppins: "'Poppins', 'Helvetica', 'Arial', sans-serif",
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",


};

export const style = {
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '12px',
    sm: '13px',
    md: '14px'
  },
  fontWeight: 'auto',
  color: 'white',
};

export const logoStyle = {
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '20px',
    sm: '30px',
    md:'40px'
  },
  fontWeight: 'bold',
  color: 'white',
};

interface Props{
  isMobile: boolean;
}

const Footer = ({isMobile}:Props) => {
  const scrollRef = React.createRef<HTMLDivElement>()

  const scrollToTop = () => {
    if(scrollRef !== null && scrollRef?.current){
        const elementTop = scrollRef.current.getBoundingClientRect().top;
        window.scrollBy({ top: elementTop , behavior: 'smooth' });
    }else{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    } 
  };


  return (
    <Stack 
      position={'relative'} 
      bgcolor={'#383E86'} px={'2rem'} pt={{xs:'1rem', sm:'2rem', md:'2rem'}} pb={'2rem'} spacing={{xs:'0.5rem', sm:'1rem', md:'2rem'}}>
      <Stack width={"100%"} justifyContent={"space-between"}>
        <Typography py={{xs:1, sm:1.5, md:2}} sx={{...logoStyle}}>RE-IDEA</Typography>
        <Stack pt={0.5} spacing={0.5}>
          <Stack onClick={()=>{window.location.href = "/privacyPolicy"}} spacing={0.5} direction={"row"} alignItems={"center"}  >
            <ArrowForwardIosIcon sx={{...style, fontWeight:"bold"}}/>
            <Typography sx={{...style, fontWeight:"bold", ":hover":{textDecoration:"underline", cursor:"pointer"}}}>{"プライバシーポリシー"}</Typography>
          </Stack>
          <Stack onClick={()=>{window.location.href = "/company"}} spacing={0.5} direction={"row"} alignItems={"center"} sx={{":hover":{textDecoration:"underline", cursor:"pointer"}}} >
            <ArrowForwardIosIcon sx={{...style, fontWeight:"bold"}}/>
            <Typography sx={{...style, fontWeight:"bold", ":hover":{textDecoration:"underline", cursor:"pointer"}}}>{"特定商取引法に基づく記載/運営会社"}</Typography>
          </Stack>
        </Stack>
      </Stack>
        <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
          <Stack>
            <Stack direction={'row'}>
              <CopyrightIcon fontSize='small' sx={{color: 'white'}}/>
              <Typography sx={{...style}}>2024 RE-IDEA. All rights reserved.</Typography>
            </Stack>
          </Stack>
          <Button onClick={scrollToTop} >
            {!isMobile && 
              <Typography sx={{...style}}>Back to Top</Typography>
            }
          </Button>
      </Stack>
    </Stack>
  )
}

export default Footer
