import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import service1Image from  '../../../img/service1.png'
import service2Image from  '../../../img/service2.png'
import service3Image from  '../../../img/service3.png'
import service4Image from  '../../../img/service4.png'
import backgroundImage from  '../../../img/background.png'
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import ServiceCard from '../../Card/serviceCard';


const Service: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, mainFontSize, contentRatio, isXS, isSmall, isLarge, isMedium ,isXL} = useContext(MyContext);
    const services = [
        {
          title: '業界最安値',
          img:service1Image,
          alt: "Lowest price in the industry",
          subTitle: isXS ? '良心的な\n価格設定' : '良心的な価格設定',
          description: isSmall ? '弊社は、挑戦される事業者様が最大の利益を得られる料金プランを提供しています。'
                            : '弊社は、挑戦される事業者様が最大の利益を得られる料金プランを提供しています。'
        },
        {
          title: '安心の英語力',
          img:service2Image,
          alt: "English skills",
          subTitle: isXS ? '多彩な\n海外実績':'多彩な海外実績',
          description: isSmall ? '帰国子女や海外事業経験者など、高度な英語能力を有するスタッフが担当しております。'
                            : '帰国子女や海外事業経験者など、高度な英語能力を有するスタッフが担当しております。'
        },
        {
          title: '事例分析',
          img:service3Image,
          alt: "Overseas analysis",
          subTitle: isXS ? '海外市場を\n熟知' : '海外市場を熟知' ,
          description: isSmall ? '海外クラファンの商品を徹底的に分析し、その知識を元に出品する商品の選定を行います。'
                            : '海外クラファンの商品を徹底的に分析し、その知識を元に出品する商品の選定を行います。'
        },
        {
          title: '海外配送代行',
          alt: "Overseas delivery",
          img:service4Image,
          subTitle: isXS ? '海外配送も\n完全サポート' : '海外配送も\n完全サポート',
          description: isSmall ? '英語での手続きやトラブルが多い海外配送も、弊社が一括して代行を行っております。'
                            : '英語での手続きやトラブルが多い海外配送も、弊社が一括して代行を行っております。'
        }
      ];

    // コンポーネントの高さを指定
    // const componentHeight = isXS ? 275 :
    //                         isSmall ? 260 :
    //                         isMedium ? 390 : 
    //                         isLarge ? 440 : 0
    const componentHeight = isXS ? 260 :
                            isSmall ? 320 :
                            isMedium ? 450 : 
                            isLarge ? 520 : 
                            isXL ? 540 : 0
    
    // コンポーネントの高さを指定
    const imgWidth = isXS ? "0%" :
                     isSmall ? "35%" :
                     isMedium ? "80%" : 
                     isLarge ? "80%" : "0%"

    const _isMobile = isXS || isSmall;
    const [isViewed, setIsViewed] = useState<boolean>(false)

    return (
        <Stack id={"service"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 6 : 15}>
            {/* 背景の点々を表示 */}
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.5, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='私たちの強み' title_en='SERVICE' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={isSmall ?  "75%" : contentRatio*100+"%"} justifyContent={"center"} zIndex={2} >
                <ObserverStack width={"100%"} isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid animationOffsetY={0} enableAnimation={true} animation={isViewed} animationDelay={0.5} animationDuration={1.2}
                        cols={_isMobile ? 2 : 4} spacingX={_isMobile ? 2 : 3} spacingY={_isMobile ? 4 : 3} items={
                        services.map((value:any, index:number) => (
                            <Stack width={"100%"}>
                                <ServiceCard alt={value.alt} display_img={!isXS} spacing={_isMobile ? 2 : 3.5} img_ratio={imgWidth} height={componentHeight} image={value.img} title={value.title} subTitle={value.subTitle} description={value.description} titleFontSize={subTitleFontSize} contentFontSize={mainFontSize} />
                            </Stack>
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default Service;