import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../router/router';
import titleBannerImage from  '../../img/titleBanner.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


interface Props {
    title:string;
    subTitle: string;
    description: string;
    image:string;
    direction: "column" | "row" | "row-reverse";

    titleFontSize: number;
    contentFontSize: number;

    width?: number;
    height?: number;
    last?: boolean;
    displayArrow?: boolean;

    isMobile?: boolean;
}

// コンテストのミニカルーセルを実装
const FlowCard: React.FC<Props> = (props) => {
    const borderRadius = 10
    return (
        <Stack width={props.width} direction={props.direction} alignItems={"center"}>
            <Stack spacing={props.isMobile ? 1 : 2} width={props.displayArrow === false ? "100%" : "90%"} height={props.height} border={"2px solid #383E86"} borderRadius={borderRadius+"px"} alignItems={"center"}>
                <Stack alignItems={"left"} px={2} width={"100%"} bgcolor={"#383E86"} border={"2px solid #383E86"} sx={{borderTopLeftRadius:borderRadius-6, borderTopRightRadius: borderRadius-6}}>
                    <Typography textAlign={props.isMobile ? "center" : undefined } color={"white"} fontWeight={600}  fontSize={props.titleFontSize-2} letterSpacing={1.2} >
                        {props.title}
                    </Typography>
                </Stack>
                <Stack width={"100%"} px={1.5} spacing={props.isMobile ? 0 : 1}>
                    <Typography display={"flex"} justifyContent={"center"} alignItems={"center"} height={(props.contentFontSize+4) * 2 + 5} color={"#202020"} textAlign={"center"} fontWeight={600} fontSize={props.contentFontSize+4} letterSpacing={1.2} >
                        {props.subTitle}
                    </Typography>
                    <Typography sx={{whiteSpace:"pre-wrap"}} color={"#202020"} fontWeight={400} fontSize={props.contentFontSize} letterSpacing={1.2} >
                        {props.description}
                    </Typography>
                </Stack>
            </Stack>
            {props.displayArrow !== false &&
                <Stack width={"10%"} position={"relative"} left={-12}>
                    {props.last !== true && <PlayArrowIcon sx={{fontSize:50, color:"#383E86", transform: props.direction === "row" ? undefined : 'rotate(90deg)',}}/>}
                </Stack>
            }

        </Stack>
    );
};

export default FlowCard;