import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from  '../../../img/background.png'
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"


const About: React.FC = () => {

    // Mycontextからプログラム全体で共有する変数を持ってこれる。
    const { subTitleFontSize, contentRatio, isXS, isSmall} = useContext(MyContext);

    // モバイル判定
    const _isMobile = (isXS || isSmall)

    // コンポーネントが画面内に入ったらisViewedがtrueになる。isOneTime={true}となっているときは、trueになったっきりで戻らない。
    const [isViewed, setIsViewed] = useState<boolean>(false)

    return (
        <Stack id={"about"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} bgcolor={"#F5FBFE"} alignItems={"center"} pt={_isMobile ? 4 : 5} pb={_isMobile ? 6 : 10}>
            <Stack component="img" alt='background about' position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.8, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='私たちにできること' title_en='ABOUT' fontSize={subTitleFontSize+5}/>
            <Stack width={contentRatio*100+"%"} mt={3} justifyContent={"center"} >
                {/* このObserverStackで囲われている部分が画面内判定の対象となる。 */}
                <ObserverStack isOneTime={true} setObserved={setIsViewed}>
                    {/* 
                        enableAnimaion: アニメーションを設定するかどうか
                        animation: 渡した引数が true になった時にアニメーション開始
                        animationDuration: アニメーションの持続時間(s)
                        animationDelay: アニメーションの遅延時間(s)
                        animationOffsetY: y座標の変化(px)

                        height: BaseGridの固定長
                        cols: BaseGridの列数
                        spacingX, spacingY: コンポーネント同士の余白
                        items: 表示するコンポーネント
                    */}
                    <BaseGrid enableAnimation={true} animation={isViewed} animationDuration={1} animationDelay={0.2} animationInitialDisplay={false} animationOffsetY={10} 
                        height={(subTitleFontSize-4)*3} cols={_isMobile ? 2 : 3} spacingX={1} spacingY={2} items={
                        ["商品選定", "掲載申請", "ページ構成・翻訳", "運営・運用", "動画・画像制作", "海外配送代行"].map((value:any, index:number) => (
                            <BaseButton text={value} height={(subTitleFontSize-4)*3+'px'} fontSize={subTitleFontSize-6} bold />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default About;