import React from 'react';
import { Stack, Typography, Theme} from '@mui/material';

interface Props {
  number: string;
  title: string;
  content: string;
  fontSize: number;
  width: number;
  displayImg: boolean;
  img: string;
  alt:string;
}

// フォントファミリーの定義
const fontFamilies = {
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

const numberStyle = {
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 900,
  background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent',
  display: 'inline-block',
  fontSize: {
    xs: '20px',
    sm: '28px',
    md: '48px',
  },
};

const titleStyle = {
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 600, // boldの代わりに数値で指定
  color: '#363636',
};

const contentStyle = {
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 400,
  color: '#363636',
  fontSize: {
    xs: '14px',
    sm: '14px',
    md: '18px',
  },
  lineHeight: {
    xs: '25px',
    sm: '35px',
    md: '42px',
  },
};

const MeritCarouselCard: React.FC<Props> = ({ number, title, content, fontSize, width, displayImg, img, alt }) => {

  return (
    <Stack 
      width={width}
      spacing={1}
      position={"relative"}
    >
      {displayImg && <Stack component="img" alt={alt} position={"absolute"} zIndex={0} top={0} left={width*0.4} src={img} width={width*0.6} height={width*0.6} sx={{ objectFit:"cover", color:"white"}}/>}
      <Stack position={"relative"} top={displayImg ? width*0.3 : undefined} width={"100%"} pb={displayImg ? width*0.3+"px" : undefined} spacing={1}>
        <Typography sx={{...numberStyle, fontSize: fontSize*4}}>{number}</Typography>
        <Typography sx={{...titleStyle, fontSize: fontSize+8}}>{title}</Typography>
        <Typography sx={{...contentStyle, fontSize: fontSize+2}}>{content}</Typography>
      </Stack>  
    </Stack>
  );
};

export default MeritCarouselCard;